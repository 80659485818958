export class ToggleMenu {
  constructor(options = {}) {
    this.defaultOptions = {
      toggleClass: 'active',
      toggleHeaderClass: 'active',
      menuSelector: '.main-menu',
      headerSelector: '.header',
      toggleSelector: '[data-toggle=menu]',
      // breakpoint: 767,
      breakpoint: 1023,
      changeHeight: false,
    };
    this.options = Object.assign(this.defaultOptions, options);

    this.menu = document.querySelector(this.options.menuSelector);
    this.header = document.querySelector(this.options.headerSelector);
    this.buttons = document.querySelectorAll(this.options.toggleSelector);
    this.hamburger = document.querySelector(this.options.toggleSelector);

    this.buttons.forEach(button => {
      button.addEventListener('click', () => this.toggle());
    });

    window.addEventListener('resize', () => this.reset());

    if (this.options.changeHeight) {
      window.addEventListener('resize', () => this.changeMenuHeight());
    }
  }

  toggle() {
    console.log(this.hamburger.classList);
    this.hamburger.classList.toggle(this.options.toggleClass);
    this.header.classList.toggle(this.options.toggleHeaderClass);

    if (this.options.changeHeight) {
      this.changeMenuHeight();
    }
  }

  reset() {
    if (window.innerWidth >= this.options.breakpoint) {
      this.hamburger.classList.remove(this.options.toggleClass);
      this.header.classList.remove(this.options.toggleHeaderClass);

      if (this.options.changeHeight) {
        this.changeMenuHeight();
      }
    }
  }

  changeMenuHeight() {
    if (window.innerWidth < this.options.breakpoint) {
      if (this.hamburger.classList.contains(this.options.toggleClass)) {
        let headerHeight = this.header.offsetHeight;
        let windowHeight = window.innerHeight;
        let diff = windowHeight - headerHeight;

        this.menu.style.height = `${diff}px`;
      } else {
        this.menu.style.height = `0px`;
      }
    } else {
      this.menu.removeAttribute('style');
    }
  }

}
